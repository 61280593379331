import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { MassageResourceStore } from './redux';

import { FindAMRAppState } from './classes/redux-states';
import FindAMRContainer from './components/find-a-mr-container';
import { BrowserRouter } from 'react-router-dom';
import { RouterToUrlQuery, addUrlProps, UrlQueryParamTypes, subquery, configureUrlQuery, urlQueryMiddleware } from 'react-url-query';

// link the history used in our app to url-query so it can update the URL with it.
// do not auto generate change handlers since we won't be using them, we'll use
// those we create in mapDispatchToProps
configureUrlQuery({ history, addChangeHandlers: false });

render(
    <Provider store={MassageResourceStore}>
        <BrowserRouter>
            <RouterToUrlQuery>
                <FindAMRContainer />
            </RouterToUrlQuery>
        </BrowserRouter>
    </Provider>

	, document.querySelector("[data-find-a-mr]"));

