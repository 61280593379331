import * as React from 'react';
import { connect } from 'react-redux';
import { FindAMRAppState } from '../classes/redux-states';
import { FindAMRApiModel } from '../redux/reducers/find-a-mr-api';
import { Dispatch } from 'redux';
import MassageResourceListComponent from './massage-resource-list';
import { getSettings } from '../redux/actions/find-a-mr-api';
import { PaginationComponent } from "./pagination/pagination";
import { setPage, setResourceType } from "../redux/actions/actions";
import { FindAMRPaginationModel } from "../redux/reducers/pagination-reducer";
import { FindAMRSettingsModel } from "../redux/reducers/find-a-mr-settings";
import FiltersContainer from './filters/filters-container';
import { pushInUrlQuery } from 'react-url-query';

interface StateToProps {
    noResults: boolean;
    currentPage: number;
    pagination: FindAMRPaginationModel;
    settings: FindAMRSettingsModel;
    totalPages: number;
    totalMassageResources: number;
    noResultsWYSIWYG: string;
}

interface DispatchToProps {
    getSettings: () => any;
    setPage: (currentPage: number) => any;
    setResourceType: (resourceType: string) => any;
}

type FindAMTContainerProps = StateToProps & DispatchToProps;

export class FindAMRContainer extends React.Component<FindAMTContainerProps> {
    constructor(props: FindAMTContainerProps) {
        super(props);

        

        this.scrollToTop = this.scrollToTop.bind(this);


    }

	scrollToTop() {
		var featureWrapper = document.querySelector('[data-find-a-mr-results]');
		featureWrapper.scrollIntoView();

		// Adjust for sticky header
		var stickyHeaderEls = document.getElementsByClassName('header-nav');
		if (stickyHeaderEls.length > 0) {
			window.scrollBy(0, stickyHeaderEls[0].clientHeight * -1);
		}
    }

    getResourceType() {
        var resourceType = (document.getElementById("resourceType") as HTMLInputElement).value;
        this.props.setResourceType(resourceType);
    }

	onPageSelect(page: number) {
        this.props.setPage(page);
        pushInUrlQuery('page', page);
		 this.scrollToTop();
	}

    componentWillMount() {
        this.getResourceType();
        this.props.getSettings();
    }

    render() {
        return (
            <div className="find-a-mr-container">
                <FiltersContainer />

                <MassageResourceListComponent />
                <PaginationComponent totalMassageResources={this.props.totalMassageResources} totalPages={this.props.totalPages} pagination={this.props.pagination} settings={this.props.settings} setCurrentPage={page => this.onPageSelect(page)} />
            </div>
        )
    }
}

function mapStateToProps(state: FindAMRAppState): StateToProps {
    return {
        noResults: state.FindAMRApiModel.noMassageResources,
        currentPage: state.FindAMRPaginationModel.currentPage,
        pagination: state.FindAMRPaginationModel,
        settings: state.FindAMRSettingsModel,
        totalPages: state.FindAMRApiModel.totalPages,
        totalMassageResources: state.FindAMRApiModel.totalMassageResources,
        noResultsWYSIWYG: state.FindAMRSettingsModel.noResultsWYSIWYG
    }
}

function mapDispatchToProps(dispatch: Dispatch<FindAMRAppState>): DispatchToProps {
    return {
        getSettings: () => dispatch(getSettings()),
        setPage: (currentPage: number) => dispatch(setPage(currentPage)),
        setResourceType: (resourceType: string) => dispatch(setResourceType(resourceType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindAMRContainer)
