import * as React from 'react';
import { Dispatch, connect } from "react-redux";
import { getMassageResources, requestMassageResources } from "../../redux/actions/find-a-mr-api";
import { setPage, updateTextFilter, setZipError, setLocationStatus } from "../../redux/actions/actions";
import { FindAMRAppState } from "../../classes/redux-states";
import { multiPushInUrlQuery } from 'react-url-query';

interface ExternalProps {
    filterName: string;
    placeholderText: string;
    filterLabel: string;
}

interface DispatchToProps {
    requestMassageResources: (page: number) => any;
    setPage: (page: number) => any;
    updateTextFilter: (filterName: string, filterValue: string) => any;
    setLocationStatus: (locationStatus: boolean) => any;
    setZipError: (zipError: boolean) => any;
}

type TextFilterProps = ExternalProps & DispatchToProps;

export class FilterItemTextComponent extends React.Component<TextFilterProps, any> {
    constructor(props: TextFilterProps) {
        super(props);

        this.textChangeEvent = this.textChangeEvent.bind(this);
        this.getResults = this.getResults.bind(this);
        this.clearResults = this.clearResults.bind(this);
    }

    textChangeEvent(event) {
        this.props.updateTextFilter(this.props.filterName, event.target.value);
        this.resultsNullCheck(event.target.value);
    }

    getResults(event) {
        if (event.which == '13') {
            if (this.checkValidZip()) {
                var zipCode = (document.getElementById('location') as HTMLInputElement).value;
                var keywordResult = (document.getElementById('keyword') as HTMLInputElement).value;
                this.props.requestMassageResources(1);
                this.props.setPage(1);
                multiPushInUrlQuery({ location: zipCode, keyword: keywordResult, page:1 });
            }
        }
    }

    checkValidZip() {
        var zipCode = (document.getElementById('location') as HTMLInputElement).value;
        var anyNumberRegex = /\d/;
        var zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;

        if (zipCode === '') {
            this.props.setZipError(false);
            return true;
        }
        if (zipCode.match(anyNumberRegex)) {
            if (!zipCode.match(zipCodeRegex)) {
                this.props.setZipError(true);
                return false;
            }
            else {
                this.props.setZipError(false);
                return true;
            }
        } 
        this.props.setZipError(false);
        return true;
    }

    clearResults() {
        this.props.updateTextFilter(this.props.filterName, '');
        (document.querySelector('.textFilter') as HTMLInputElement).value = '';
        (document.querySelector('.textFilter') as HTMLInputElement).focus();
        (document.querySelector('[data-text-panel]') as HTMLElement).classList.add('inactive');
    }

    resultsNullCheck(value) {
        let searchPanel = document.querySelector('[data-text-panel]');
        if (value != '') {
            searchPanel.classList.remove('inactive');
        }
        else {
            searchPanel.classList.add('inactive');
        }
    }

    getURLSearchParams(name) {
        var searchTermFromQuery = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href); // gets a specific value from the location object's URL and filters out any symbols that may be in the URL
        if (searchTermFromQuery == null) {
            return null;
        }
        else {
            return decodeURI(searchTermFromQuery[1]) || 0;
        }
    }

    getKeyowrdFromQueryString() {

        var locationFromQueryString = this.getURLSearchParams("keyword");

        if (locationFromQueryString != null && locationFromQueryString != "") {
            this.props.updateTextFilter("keyword", locationFromQueryString.toString());
            var locationCodeTextBox = document.getElementById("keyword");
            (locationCodeTextBox as HTMLInputElement).value = locationFromQueryString.toString();
        }
    }

    componentDidMount() {
        this.getKeyowrdFromQueryString();
        var keywordSearch = (document.getElementById('keyword') as HTMLInputElement).value;
        if (keywordSearch != '') {
            this.resultsNullCheck(keywordSearch);
        }
    }

    render() {
        return (
            <div className="find-a-mr-filters-panel-item find-a-mr-filters-panel-item-field" >
                <label className="visually-hidden" htmlFor={this.props.filterName}>{this.props.filterLabel}</label>
                <input className="textFilter input-lg" id={this.props.filterName} type="text" name={this.props.filterName} placeholder={this.props.placeholderText} onKeyPress={this.getResults} onChange={this.textChangeEvent} />
                <div className=" find-a-mr-filters-panel-item-svg inactive" data-text-panel >

                    <button className="un-btn icon-close" type="button" onClick={this.clearResults}>
                        <svg className="svg-icon" aria-hidden="true" focusable="false" >
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-close"> </use>
                            <span className="visually-hidden">Clear Search</span>
                        </svg>
                    </button>
                    <button className="un-btn icon-search" tabIndex={-1}>
                        <svg className="svg-icon" aria-hidden="true" focusable="false" >
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-search-sm"></use>
                        </svg>
                        <span className="visually-hidden">Enter Search</span>
                    </button>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: Dispatch<FindAMRAppState>): DispatchToProps {
    return {
        requestMassageResources: (page: number) => dispatch(getMassageResources(page)),
        setPage: (page: number) => dispatch(setPage(page)),
        updateTextFilter: (filterName: string, filterValue: string) => dispatch(updateTextFilter(filterName, filterValue)),
        setLocationStatus: (locationStatus: boolean) => dispatch(setLocationStatus(locationStatus)),
        setZipError: (zipError: boolean) => dispatch(setZipError(zipError))
    }
}

export default connect<null, DispatchToProps, ExternalProps>(null, mapDispatchToProps)(FilterItemTextComponent);