import * as React from 'react';
import { FindAMRPaginationModel } from '../../redux/reducers/pagination-reducer';
import { FindAMRSettingsModel } from '../../redux/reducers/find-a-mr-settings';
import { FindAMRAppState } from "../../classes/redux-states";
import { Dispatch, connect } from "react-redux";
import { setPage } from "../../redux/actions/actions";
import { PagingArrows } from "./paging-arrows";
import { Paging } from "./paging";

export interface StateToProps {
    pagination: FindAMRPaginationModel;
    settings: FindAMRSettingsModel;
    totalPages: number;
    totalMassageResources: number;
}

export interface DispatchToProps {
    setCurrentPage (page: number): any;
}


type PaginationProps = DispatchToProps & StateToProps;


export class PaginationComponent extends React.Component<PaginationProps, any> {
    constructor(props: PaginationProps) {
        super(props);
    }



    render() {
        return (
            <div className="pagination-container">
                <PagingArrows paging={this.props.pagination} isLeftArrows={true} onPageSelect={this.props.setCurrentPage} totalMassageResources={this.props.totalMassageResources} totalPages={this.props.totalPages} />

                <div className="pagination-desktop">
					<Paging totalPages={this.props.totalPages} paging={this.props.pagination} onPageSelect={this.props.setCurrentPage} numberOfButtonsToShow={8} />
                </div>

                <div className="pagination-mobile">
					<Paging paging={this.props.pagination} totalPages={this.props.totalPages} onPageSelect={this.props.setCurrentPage} numberOfButtonsToShow={7} />
                </div>

                <PagingArrows paging={this.props.pagination} isLeftArrows={false} onPageSelect={this.props.setCurrentPage} totalMassageResources={this.props.totalMassageResources} totalPages={this.props.totalPages} />
            </div>
        );
    }
}

function mapStateToProps(state: FindAMRAppState): StateToProps {
    return {
        pagination: state.FindAMRPaginationModel,
        settings: state.FindAMRSettingsModel,
        totalPages: state.FindAMRApiModel.totalPages,
        totalMassageResources: state.FindAMRApiModel.totalMassageResources
    }
}

function mapDispatchToProps(dispatch: Dispatch<FindAMRAppState>): DispatchToProps {
    return {
		setCurrentPage: (currentPage: number) => dispatch(setPage(currentPage))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginationComponent)