import { assign, groupBy } from 'lodash';
import { ActionType } from '../actions/actions';
import { IAction } from '../../classes/action';

export interface FindAMTFiltersModel {
    location: string;
    keyword: string;
    latitude: string;
    longitude: string;
}

export const defaultFindAMTFiltersModel: FindAMTFiltersModel = {
    location: "",
    keyword: "",
    latitude: "",
    longitude: ""
}

export default function filtersReducer(state: FindAMTFiltersModel = defaultFindAMTFiltersModel, action: IAction) {
    switch (action.type) {
        case ActionType.UPDATE_TEXT_FILTER: {
            const filterName = action.value.filterName
            if (filterName === "location")
                return assign({}, state, { location: action.value.filterValue });
            else if (filterName === "keyword")
                return assign({}, state, { keyword: action.value.filterValue });
            else if (filterName === "latitude")
                return assign({}, state, { latitude: action.value.filterValue });
            else if (filterName === "longitude")
                return assign({}, state, { longitude: action.value.filterValue });
        }

        default:
            return state;
    }
}