import * as React from 'react';
import { connect } from 'react-redux';
import { FindAMRAppState } from '../classes/redux-states';
import { Dispatch } from 'redux';
import { IAMTAMassageResource } from '../classes/massageResource';
import { ActionType, setContent } from '../redux/actions/actions';

export interface ExternalProps {
    mentor: IAMTAMassageResource;
}

interface DispatchToProps {
    setContent: (content: IAMTAMassageResource) => any;
}

export interface StateToProps {
    location: string;
    locationStatus: boolean;
    hasLatLong: boolean;
}

interface MentorItemState { }

type MentorItemProps = ExternalProps & DispatchToProps & StateToProps;

export class MentorItemComponent extends React.Component<MentorItemProps, MentorItemState> {
    constructor(props: MentorItemProps) {
        super(props);
    }

    getMentorNameContent() {
        if (this.props.mentor.urlToDetailsPage != null)
            return <h5 className="find-a-mr-results-list-item-title"><a href={this.props.mentor.urlToDetailsPage}>{this.props.mentor.resourceName}</a></h5>;
        else
            return <h5 className="find-a-mr-results-list-item-title">{this.props.mentor.resourceName}</h5>;
    }

    render() {
        return (
            <li className="find-a-mr-results-list-item">
                <div className="find-a-mr-results-list-item-content">
                    <div className="find-a-mr-results-list-item-image">
                        <img src={this.props.mentor.urlToThumbnailImage} />
                    </div>

                    <div className="find-a-mr-results-list-item-info">
                        {
                            this.getMentorNameContent()
                        }
                        <div className="find-a-mr-results-list-item-address">
                            <p>{this.props.mentor.officeAddressLine1}, {this.props.mentor.officeAddressLine2}</p>

                        </div>
                        <div className="find-a-mr-results-list-item-skills">
                            <p>{this.props.mentor.description}</p>
                        </div>
                        {
                            this.props.locationStatus || this.props.hasLatLong ?
                                <div className="find-a-mr-results-filter-approx">
                                    <svg className="svg-icon" aria-hidden="true" focusable="false">
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-location" />
                                    </svg>
                                    <span className="visually-hidden">Zip Code</span>
                                    <p>
                                        <span>{this.props.mentor.officeDistanceFromZip} miles</span> away</p>
                                </div> : null
                        }
                    </div>
                </div>
            </li>
                
        )
    }
}

function mapStateToProps(state: FindAMRAppState): StateToProps {
    return {
        location: state.FindAMRFiltersModel.location,
        locationStatus: state.FindAMRApiModel.locationStatus,
        hasLatLong: (state.FindAMRFiltersModel.latitude !== "" && state.FindAMRFiltersModel.longitude !== "")
    }
}

function mapDispatchToProps(dispatch: Dispatch<FindAMRAppState>, ownProps: ExternalProps): DispatchToProps {
    return {
        setContent: (content: IAMTAMassageResource) => dispatch(setContent(content))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MentorItemComponent)