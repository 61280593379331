import * as React from 'react';
import MassageTherapistItemComponent from './massage-therapist-item';
import MassageSchoolItemComponent from './massage-school-item';
import { IAMTAMassageResource } from '../classes/massageResource';
import { FindAMRAppState } from '../classes/redux-states';
import { connect, Dispatch } from 'react-redux';
import { getMassageResources, getSettings } from '../redux/actions/find-a-mr-api';
import { FindAMRSettingsModel } from "../redux/reducers/find-a-mr-settings";
import MentorItemComponent from './mentor-item';


interface StateToProps {
    massageResources: any[];
    state: FindAMRAppState;
    totalMassageResources: number;
    settings: FindAMRSettingsModel;
    totalPages: number;
    noResultsWYSIWYG: string;
}

interface DispatchToProps {
    getMassageResources: (page: number) => any;
    getSettings: () => any;
}

type MassageResourceListProps = StateToProps & DispatchToProps;

export class MassageResourceListComponent extends React.Component<MassageResourceListProps, null> {
    constructor(props: MassageResourceListProps) {
        super(props);
        this.props.getSettings();
    }
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    currentResultsNumber() {
        if (this.props.totalMassageResources == 0)
            return;
        if (this.props.state.FindAMRPaginationModel.currentPage * 10 > this.props.totalMassageResources) {
            return (((this.props.state.FindAMRPaginationModel.currentPage - 1) * 10) + 1).toString() + "-" + this.props.totalMassageResources.toString() + ' ' + 'of';
        }
        else if (this.props.totalMassageResources == 1) {
            return "1";
        }
        else {
            return (((this.props.state.FindAMRPaginationModel.currentPage - 1) * 10) + 1).toString() + "-" + (this.props.state.FindAMRPaginationModel.currentPage * 10).toString() + ' ' + 'of';
        }
    }

    getListingType() {
        if (this.props.settings.resourceType === "therapist") {
            return this.props.massageResources.map(therapist => <MassageTherapistItemComponent therapist={therapist} key={this.props.massageResources.indexOf(therapist)} />);

        }
        else if (this.props.settings.resourceType === "school") {
            return this.props.massageResources.map(school => <MassageSchoolItemComponent school={school} key={this.props.massageResources.indexOf(school)} />);
        }
        else if (this.props.settings.resourceType === "mentor") {
            return this.props.massageResources.map(mentor => <MentorItemComponent mentor={mentor} key={this.props.massageResources.indexOf(mentor)} />);
        }
        else
            return null;
    }

    resultsStatus() {
        if (this.props.state.FindAMRApiModel.initialLoad == true)
            return false;
        else if (this.props.state.FindAMRApiModel.initialLoad == false) {
            if (this.props.massageResources.length == 0) {
                return true;
            }
            else return false;
        }
        else return false;
    }

    render() {
        return (
            <div className="find-a-mr-results-container" data-find-a-mr-results>
                {
                    !this.props.state.FindAMRApiModel.initialLoad ?
                        <div className="find-a-mr-results-info-message">
                            <p>{this.currentResultsNumber()} {this.numberWithCommas(this.props.totalMassageResources)} {this.props.settings.resourceType + 's'} </p>
                        </div> : null
                }
                <div className="find-a-mr-results-info-container">
                </div>

                <ul className="find-a-mr-results-list">
                    {
                        this.getListingType()
                    }
                </ul>
                {
                    this.resultsStatus() ?

                        <div className="find-a-mr-no-results-container rich-text" dangerouslySetInnerHTML={{ __html: this.props.noResultsWYSIWYG }} >

                        </div> : null

                }

            </div>
        )
    }
}

function mapStateToProps(state: FindAMRAppState): StateToProps {
    return {
        massageResources: state.FindAMRApiModel.massageResourceList || [],
        state: state,
        totalMassageResources: state.FindAMRApiModel.totalMassageResources,
        settings: state.FindAMRSettingsModel,
        totalPages: state.FindAMRApiModel.totalPages,
        noResultsWYSIWYG: state.FindAMRSettingsModel.noResultsWYSIWYG
    }
}

function mapDispatchToProps(dispatch: Dispatch<FindAMRAppState>): DispatchToProps {
    return {
        getMassageResources: (page: number) => dispatch(getMassageResources(page)),
        getSettings: () => dispatch(getSettings())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MassageResourceListComponent)