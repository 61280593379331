import * as React from 'react';
import { getMassageResources, requestMassageResources, clearResults } from "../../redux/actions/find-a-mr-api";
import { Dispatch, connect } from "react-redux";
import { FindAMRAppState } from "../../classes/redux-states";
import { FindAMRSettingsModel } from "../../redux/reducers/find-a-mr-settings";
import { setPage, updateNumberFilter, updateTextFilter, setZipError, setLocationStatus } from "../../redux/actions/actions";
import { throttle, debounce } from 'lodash';
import FilterItemLocationCodeComponent from "./filter-item-location";
import FilterItemTextComponent from "./filter-item-text";
import { addUrlProps, UrlQueryParamTypes, subquery, multiPushInUrlQuery, pushInUrlQuery } from 'react-url-query';

interface DispatchToProps {
    requestMassageResources: (page: number) => any;
    setPage: (page: number) => any;
    updateTextFilter: (filterName: string, filterValue: string) => any;
    updateNumberFilter: (filterName: string, filterValue: number) => any;
    setLocationStatus: (locationStatus: boolean) => any;
    clearResults: () => any;
    setZipError: (zipError: boolean) => any;
}

interface StateToProps {
    currentPage: number;
}

type FiltersContainerProps = DispatchToProps & StateToProps;

export class FiltersContainer extends React.Component<FiltersContainerProps, any> {
    constructor(props: FiltersContainerProps) {
        super(props);
        this.remeasurePanel = this.remeasurePanel.bind(this);

        this.getResults = this.getResults.bind(this);
        this.getResultsButton = this.getResultsButton.bind(this);
        this.getLocationButton = this.getLocationButton.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
    }

    getResultsButton() {
        if (this.checkValidZip()) {
            this.props.updateTextFilter("latitude", "");
            this.props.updateTextFilter("longitude", "");
            var zipCode = (document.getElementById('location') as HTMLInputElement).value;
            var keywordResult = (document.getElementById('keyword') as HTMLInputElement).value;
            this.props.setPage(1);
            multiPushInUrlQuery({ location: zipCode, keyword: keywordResult, latitude: "", longitude: "",page: 1 });
            this.props.requestMassageResources(1);
        }
    }

    getLocationButton() {
        function getPosition(position) {
            let lat = position.coords.latitude.toString();
            let long = position.coords.longitude.toString();
            let path = window.location.pathname;  
            window.location.replace(path + "?latitude=" + lat + "&longitude=" + long + "&location=&page=1#search-section");

            //multiPushInUrlQuery({ latitude: lat, longitude: long, page: 1, location: "" });
            //this.props.setPage(1);
            //this.props.requestMassageResources(1);
        }

        function denied() {
            alert("AMTA website does not have permission to use your location.");
            document.getElementById("geolocation-link").classList.add("amta-hidden");
        }

        var options = {
            enableHighAccuracy: true,
        };

        navigator.geolocation.getCurrentPosition(getPosition, denied, options);           
    }

    getResults() {
        if (this.checkValidZip()) {
            var pageFromQueryString = this.getURLSearchParams("page");
            if (pageFromQueryString !== null && pageFromQueryString !== "") {
                var page = Number(pageFromQueryString);
                this.props.setPage(page);
                this.props.requestMassageResources(page);
            }
            else {
                this.props.requestMassageResources(1);
            }
            
        }
    }

    checkValidZip() {
        var zipCode = (document.getElementById('location') as HTMLInputElement).value;
        var anyNumberRegex = /\d/;
        var zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
        if (zipCode === '') {
            this.props.setZipError(false);
            return true;
        }
        if (zipCode.match(anyNumberRegex)) {
            if (!zipCode.match(zipCodeRegex)) {
                this.props.setZipError(true);
                return false;
            }
            else {
                this.props.setZipError(false);
                return true;
            }
        } 
        this.props.setZipError(false);
        return true;
    }

    getClearResults() {
        this.props.clearResults();
        this.props.setPage(1);
    }

    clearFilters() {
        var textBoxes = document.getElementsByClassName("textFilter");
        for (var i = 0; i < textBoxes.length; i++) {

            (textBoxes[i] as HTMLInputElement).value = "";
        }
        var locationCodeTextBox = document.getElementById("location");
        (locationCodeTextBox as HTMLInputElement).value = "";
        var mileRadiusDropdown = document.getElementById("mileRadius") as HTMLSelectElement;
        mileRadiusDropdown.selectedIndex = 0;
        var countryDropdown = document.getElementById("country") as HTMLSelectElement;
        countryDropdown.selectedIndex = 0;
        var stateDropdown = document.getElementById("state") as HTMLSelectElement;
        stateDropdown.selectedIndex = 0;

        this.props.updateTextFilter("location", "");
        this.props.updateTextFilter("keyword", "");        

        this.getClearResults();
    }

    remeasurePanel(panelContainer, panel) {
        panelContainer.style.height = panel.clientHeight + 'px';
    }

    getURLSearchParams(name) {
        var searchTermFromQuery = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href); // gets a specific value from the location object's URL and filters out any symbols that may be in the URL
        if (searchTermFromQuery == null) {
            return null;
        }
        else {
            return decodeURI(searchTermFromQuery[1]) || 0;
        }
    }

    getQueryStringParams() {

        var locationFromQueryString = this.getURLSearchParams("location");

        if (locationFromQueryString != null && locationFromQueryString != "") {
            this.props.updateTextFilter("location", locationFromQueryString.toString());
            var locationCodeTextBox = document.getElementById("location");
            (locationCodeTextBox as HTMLInputElement).value = locationFromQueryString.toString();

        }

        var keywordsFromQueryString = this.getURLSearchParams("keyword");

        if (keywordsFromQueryString !== null && keywordsFromQueryString !== "") {
            this.props.updateTextFilter("keyword", keywordsFromQueryString.toString());
            var keywordsTextBox = document.getElementById("keyword");
            (keywordsTextBox as HTMLInputElement).value = keywordsFromQueryString.toString();
        }

        var latitudeFromQueryString = this.getURLSearchParams("latitude");

        if (latitudeFromQueryString !== null && latitudeFromQueryString !== "") {
            this.props.updateTextFilter("latitude", latitudeFromQueryString.toString());
        }

        var longitudeFromQueryString = this.getURLSearchParams("longitude");

        if (longitudeFromQueryString !== null && longitudeFromQueryString !== "") {
            this.props.updateTextFilter("longitude", longitudeFromQueryString.toString());
        }

        var pageFromQueryString = this.getURLSearchParams("page");

        if (pageFromQueryString !== null && pageFromQueryString !== "") {
            this.props.setPage((Number(pageFromQueryString)));
        }
        else {
            this.props.setPage(1);
        }

        if (pageFromQueryString || keywordsFromQueryString || locationFromQueryString || latitudeFromQueryString || longitudeFromQueryString) {
            return true;
        }
        else {
            return false;
        }
    }

    checkGeoLocationStatus() {
        (navigator as any).permissions.query({ name: 'geolocation' }).then(function (result) {
            if (result.state === 'denied') {
                document.getElementById("geolocation-link").classList.add("amta-hidden");
            } else {
                document.getElementById("geolocation-link").classList.remove("amta-hidden");
            }
        });
    }

    componentDidMount() {
        if (this.getQueryStringParams()) {
            this.getResults();
        }

        this.checkGeoLocationStatus();
    }

    render() {

        return (
            <div className="find-a-mr-filters-container" id="search-section">
                <div className="find-a-mr-filters-panel-container" id="find-a-mr-filters-panel" aria-hidden="false">
                    <div className="find-a-mr-filters-panel find-a-mr-filters-panel-default">
                        <FilterItemTextComponent filterName="keyword" filterLabel="Keyword" placeholderText="Name or Keyword" />
                        <FilterItemLocationCodeComponent />
                        <div className="find-a-mr-filters-panel-item find-a-mr-filters-panel-item-search">
                            <button className="btn-primary" onClick={this.getResultsButton}>Search</button>
                        </div>
                    </div>
                </div>
                    <div className="find-a-mr-filters-panel-container" id="geolocation-link" aria-hidden="false">
                        <div className="find-a-mr-filters-panel amta-justify-content-right">
                            <div className="find-a-mr-filters-panel-item find-a-mr-filters-panel-item-search">
                                <a className="amta-search-link" onClick={this.getLocationButton}>
                                    <svg className="amta-search-icon" aria-hidden="true" focusable="false">
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-location"></use>
                                    </svg>
                                    <span> Use my current location</span>
                                </a>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch: Dispatch<FindAMRAppState>): DispatchToProps {
    return {
        requestMassageResources: (page: number) => dispatch(getMassageResources(page)),
        setPage: (page: number) => dispatch(setPage(page)),
        updateNumberFilter: (filterName: string, filterValue: number) => dispatch(updateNumberFilter(filterName, filterValue)),
        updateTextFilter: (filterName: string, filterValue: string) => dispatch(updateTextFilter(filterName, filterValue)),
        setLocationStatus: (locationStatus: boolean) => dispatch(setLocationStatus(locationStatus)),
        clearResults: () => dispatch(clearResults()),
        setZipError: (zipError: boolean) => dispatch(setZipError(zipError))
    }
}

function mapStateToProps(state: FindAMRAppState): StateToProps {
    return {
        currentPage: state.FindAMRPaginationModel.currentPage,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersContainer);