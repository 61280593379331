import { IAMTAMassageResource } from '../../classes/massageResource';

export enum ActionType {
    REQUEST_NEXT_BATCH,
    RECEIVE_NEXT_BATCH,
    SET_CONTENT,
    SET_PAGE,
    REQUEST_SETTINGS,
    RECEIVE_SETTINGS,
    CLEAR_RESULTS,
    UPDATE_TEXT_FILTER,
    UPDATE_NUMBER_FILTER,
    UPDATE_LOC_STATUS,
    SET_RESOURCE_TYPE,
    SET_ZIP_ERROR
};

export function updateTextFilter(filterName: string, filterValue: string) {
    return {
        type: ActionType.UPDATE_TEXT_FILTER,
        value: {
            filterName: filterName,
            filterValue: filterValue
        }
    }
}

export function updateNumberFilter(filterName: string, filterValue: number) {
    return {
        type: ActionType.UPDATE_NUMBER_FILTER,
        value: {
            filterName: filterName,
            filterValue: filterValue
        }
    }
}

export function setZipError(error: boolean) {
    return {
        type: ActionType.SET_ZIP_ERROR,
        value: {
            zipError: error
        }
    }
}

export function setContent(content: IAMTAMassageResource) {
    return {
        type: ActionType.SET_CONTENT,
        value: content
    }
}

export function setPage(page: number) {
    return {
        type: ActionType.SET_PAGE,
        value: page
    }
}

export function setLocationStatus(locationStatus: boolean) {
    return {
        type: ActionType.UPDATE_LOC_STATUS,
        value: locationStatus
    }
}

export function setResourceType(resourceType: string) {
    return {
        type: ActionType.SET_RESOURCE_TYPE,
        value: resourceType
    }
}
