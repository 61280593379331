import * as React from 'react';
import { FindAMRPaginationModel } from '../../redux/reducers/pagination-reducer';
import { connect, Dispatch } from "react-redux";
import { FindAMRSettingsModel } from "../../redux/reducers/find-a-mr-settings";
import { FindAMRAppState } from "../../classes/redux-states";
import { setPage } from "../../redux/actions/actions";

export interface IPagingArrowsProps {
    paging: FindAMRPaginationModel;
    isLeftArrows: boolean;
    onPageSelect(page: number): void;
    totalPages: number;
    totalMassageResources: number;
}

/// <summary>
/// Renders left or right page arrow content
/// </summary>
/// <returns>Content text</returns>
export class PagingArrows extends React.Component<IPagingArrowsProps, any> {
    /// <summary>
    /// Calculates left or right page arrow content
    /// </summary>
    /// <returns>Left or right arrow JSX content</returns>
    GetResult() {
        if (this.props.totalMassageResources === 0) return null;

        const numberOfPages = this.props.totalPages;

        if (this.props.isLeftArrows) {
            const isLeftDisabled = this.props.paging.currentPage === 1;
            const classNames = "pagination-arrows pagination-arrows-left" + (isLeftDisabled ? " pagination-arrows-disabled" : "");
            return (
				<div className={classNames}>
					<button className="pagination-arrows-item" onClick={() => isLeftDisabled ? false : this.props.onPageSelect(1)} disabled={isLeftDisabled ? true : false}>
                        <svg className="svg-icon" aria-hidden="true" focusable="false">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-chevron-double" />
                        </svg>
                        <span className="visually-hidden">First Page</span>
					</button>
					<button className="pagination-arrows-item" onClick={() => isLeftDisabled ? false : this.props.onPageSelect(this.props.paging.currentPage - 1)} disabled={isLeftDisabled ? true : false}>
                        <svg className="svg-icon" aria-hidden="true" focusable="false">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-chevron" />
                        </svg>
                        <span className="visually-hidden">Previous Page</span>
                    </button>
                </div>
            );
        }
        else {
            const isRightDisabled = this.props.paging.currentPage === this.props.totalPages;
            const classNames = "pagination-arrows pagination-arrows-right" + (isRightDisabled ? " pagination-arrows-disabled" : "");
            return (
                <div className={classNames}>
                    <button className="pagination-arrows-item" onClick={() => isRightDisabled ? false : this.props.onPageSelect(this.props.paging.currentPage + 1)} disabled={isRightDisabled ? true : false}>
                        <svg className="svg-icon" aria-hidden="true" focusable="false">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-chevron" />
                        </svg>
                        <span className="visually-hidden">Next Page</span>
                    </button>
                    <button className="pagination-arrows-item" onClick={() => isRightDisabled ? false : this.props.onPageSelect(numberOfPages)} disabled={isRightDisabled ? true : false}>
                        <svg className="svg-icon" aria-hidden="true" focusable="false">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-chevron-double" />
                        </svg>
                        <span className="visually-hidden">Last Page</span>
                    </button>
                </div>
            );
        }
    }

    render() {
        return this.GetResult();
    }
}


function mapDispatchToProps(dispatch) {
	return {
		onPageSelect: (currentPage: number) => dispatch(setPage(currentPage))
	};
}  