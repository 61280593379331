import * as _ from 'lodash';
import { ActionType, setLocationStatus } from './actions';
import { IAMTAMassageResource } from '../../classes/massageResource';
import { FindAMRSettings } from '../../classes/appSettings';
import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'react-redux';
import { FindAMRAppState } from '../../classes/redux-states';

export function requestMassageResources() {
    return {
        type: ActionType.REQUEST_NEXT_BATCH
    }
}

function requestSettings() {
    return {
        type: ActionType.REQUEST_SETTINGS
    }
}

function settingsResponse(response: FindAMRSettings) {
    return {
        type: ActionType.RECEIVE_SETTINGS,
        value: {
            FindAMRSettings: response
        }
    }
}

function clearMassageResourceResults() {
    return {
        type: ActionType.CLEAR_RESULTS
    }
}

interface MassageResourceResponse {
    massageResources: IAMTAMassageResource[];
    totalMassageResources: number;
    totalPages: number;
    searchedForState: boolean;
}

function massageResourcesResponse(response: MassageResourceResponse) {
    return {
        type: ActionType.RECEIVE_NEXT_BATCH,
        value: {
            massageResources: response.massageResources,
            totalMassageResources: response.totalMassageResources,
            totalPages: response.totalPages,
            searchedForState: response.searchedForState
        }
    }
}

export function getSettings() {
    return (dispatch: Dispatch<FindAMRAppState>, getState: () => FindAMRAppState) => {
        let state = getState();
        dispatch(requestSettings());
        axios.post<FindAMRSettings>('/api/v1/famr/getSettings',
            {
                ResourceType: state.FindAMRSettingsModel.resourceType
            })
            .then(response => {
                const settings = response.data;
                state = getState();
                dispatch(settingsResponse(settings));
            })
    }
}

export function getMassageResources(page: number) {
    return (dispatch: Dispatch<FindAMRAppState>, getState: () => FindAMRAppState) => {
        let state = getState();
        let currentPage = page == 0 ? state.FindAMRPaginationModel.currentPage : page;
        dispatch(requestMassageResources());
        axios.post<MassageResourceResponse>('/api/v1/famr/getMassageResources',
            {
                CurrentPage: currentPage,
                Location: state.FindAMRFiltersModel.location,
                Keyword: state.FindAMRFiltersModel.keyword,
                ResourceType: state.FindAMRSettingsModel.resourceType,
                Latitude: state.FindAMRFiltersModel.latitude,
                Longitude: state.FindAMRFiltersModel.longitude
            }
        ).then(response => {
            const massageResourceResponse = response.data;
            //put filtering here 
            state = getState();
            dispatch(massageResourcesResponse(massageResourceResponse));
            if (state.FindAMRFiltersModel.location != "" && !massageResourceResponse.searchedForState) {
                dispatch(setLocationStatus(true));
            }
            else {
                dispatch(setLocationStatus(false));
            }
        })
    }
}

export function clearResults() {
    return (dispatch: Dispatch<FindAMRAppState>, getState: () => FindAMRAppState) => {
        let state = getState();
        dispatch(clearMassageResourceResults());
    }
}