import { assign, groupBy } from 'lodash';
import { ActionType } from '../actions/actions';
import { IAction } from '../../classes/action';



export interface FindAMRSettingsModel {
    totalMassageResources: number;
    totalPages: number;
    countries: string[];
    noResultsWYSIWYG: string;
    resourceType: string;
}

export const defaultFindAMRSettingsModel: FindAMRSettingsModel = {
    totalMassageResources: 0,
    totalPages: 0,
    countries: [],
    noResultsWYSIWYG: '',
    resourceType: ''
};

export default function findAMRSettingsReducer(state: FindAMRSettingsModel = defaultFindAMRSettingsModel, action: IAction) {
    switch (action.type) {
        case ActionType.REQUEST_SETTINGS: {
            return assign({}, state, { requestingSettings: true })
        }

        case ActionType.RECEIVE_SETTINGS: {
            const settings = action.value.FindAMRSettings;
            const MassageResourcesCount = settings.totalMassageResources;
            const pageCount = settings.totalPages;
            const countries = settings.countries;
            const noResultsWYSIWYG = settings.noResultsWYSIWYG;
            return assign({}, state, { requestingSettings: false, totalMassageResources: MassageResourcesCount, totalPages: pageCount, countries: countries, noResultsWYSIWYG: noResultsWYSIWYG })
        }

        case ActionType.SET_RESOURCE_TYPE: {
            const resourceType = action.value;
            return assign({}, state, { resourceType: resourceType });
        }
        default:
            return state;
    }
}
