/* Returns a window of the given items array */
export function getRangeCenteredAt<T>(centerIdx: number, size: number, items: T[]): T[] {
    var flanking = Math.floor(size / 2);

    if (centerIdx - flanking <= 0) {
        return items.slice(0, size);
    }

    if (centerIdx + flanking > items.length - 1) {
        const sliceIdx = Math.max(0, items.length - size);
        return items.slice(sliceIdx);
    }
    if (size % 2 == 0) {
        return items.slice(centerIdx - flanking, centerIdx + flanking);
    }
    else {
        //this fixes issue when setting odd number of buttons to display to allow for clickable buttons on either side of the active page
        return items.slice(centerIdx - (flanking + 1), centerIdx - (flanking + 1) + size);
    }
}
