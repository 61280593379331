import { assign, groupBy } from 'lodash';
import { ActionType } from '../actions/actions';
import { IAction } from '../../classes/action';
import { IAMTAMassageResource } from '../../classes/massageResource';
import { FindAMRSettings } from '../../classes/appSettings';

export interface FindAMRApiModel {
    requestingData: boolean;
    massageResourceList: any[];
    filteredMassageResourceList: any[];
    currentBatch: number;
    noMassageResources: boolean;
    requestingSettings: boolean;
    totalMassageResources: number;
    totalPages: number;
    initialLoad: boolean;
    locationStatus: boolean;
    zipError: boolean;
    searchedForState: boolean;
}

export const defaultFindAMRApiModel: FindAMRApiModel = {
    requestingData: false,
    massageResourceList: [],
    currentBatch: 0,
    filteredMassageResourceList: [],
    noMassageResources: false,
    requestingSettings: false,
    totalMassageResources: 0,
    totalPages: 0,
    initialLoad: true,
    locationStatus: false,
    zipError: false,
    searchedForState: false
};

export default function findAMRApiReducer(state: FindAMRApiModel = defaultFindAMRApiModel, action: IAction) {
    switch (action.type) {
        case ActionType.REQUEST_NEXT_BATCH: {
            return assign({}, state, { requestingData: true, currentBatch: state.currentBatch + 1 });
        }

        case ActionType.RECEIVE_NEXT_BATCH: {
            const resources = action.value.massageResources;
            const resourceCount = action.value.totalMassageResources;
            const totalPages = action.value.totalPages;
            const searchedForState = action.value.searchedForState;
            //do any needed grouping here

            const noResources = resources.length == 0 ? true : false;

            return assign({}, state, {
                initialLoad: false, requestingData: false, massageResourceList: resources, noMassageResources: noResources, totalMassageResources
                    : resourceCount, totalPages: totalPages, searchedForState: searchedForState
            })
        }

        case ActionType.CLEAR_RESULTS: {
            return assign({}, state, { initialLoad: true, requestingData: false, massageResourceList: [], noMassageResources: false, totalMassageResources: 0, totalPages: 0, searchedForState: false })
        }

        case ActionType.UPDATE_LOC_STATUS: {
            const locationStatus = action.value;
            return assign({}, state, { locationStatus: locationStatus });
        }

        case ActionType.SET_ZIP_ERROR: {
            const zipError = action.value.zipError;
            return assign({}, state, { zipError: zipError })
        }

        default:
            return state;
    }
}