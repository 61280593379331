import * as React from 'react';
import { connect } from 'react-redux';
import { FindAMRAppState } from '../classes/redux-states';
import { Dispatch } from 'redux';
import { IAMTAMassageResource } from '../classes/massageResource';
import { ActionType, setContent } from '../redux/actions/actions';

export interface ExternalProps {
    school: IAMTAMassageResource;
}

interface DispatchToProps {
    setContent: (content: IAMTAMassageResource) => any;
}

export interface StateToProps {
    zipCode: string;
    zipCodeStatus: boolean;
    hasLatLong: boolean;
}

interface MassageSchoolItemState {
}

type MassageSchoolItemProps = ExternalProps & DispatchToProps & StateToProps;

export class MassageSchoolItemComponent extends React.Component<MassageSchoolItemProps, MassageSchoolItemState> {
    constructor(props: MassageSchoolItemProps) {
        super(props);
    }


    getTherapistNameContent() {
        if (this.props.school.urlToDetailsPage != null)
            return <h5 className="find-a-mr-results-list-item-title"><a href={this.props.school.urlToDetailsPage}>{this.props.school.resourceName}</a></h5>;
        else
            return <h5 className="find-a-mr-results-list-item-title">{this.props.school.resourceName}</h5>;
    }

    render() {
        return (
            <li className="find-a-mr-results-list-item">
                <div className="find-a-mr-results-list-item-content">
                    <div className="find-a-mr-results-list-item-image">
                        <img src={this.props.school.urlToThumbnailImage} />
                    </div>

                    <div className="find-a-mr-results-list-item-info">
                        {
                            this.getTherapistNameContent()
                        }
                        <div className="find-a-mr-results-list-item-address">
                            <p className="">{this.props.school.officeAddressLine1} </p>
                            <span>|</span>
                            <p className="">{this.props.school.officeAddressLine2}</p>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: this.props.school.description }}></p>
                        {
                            this.props.zipCodeStatus || this.props.hasLatLong ?

                                <div className="find-a-mr-results-filter-approx">
                                    <svg className="svg-icon" aria-hidden="true" focusable="false">
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-location" />
                                    </svg>
                                    <span className="visually-hidden">Zip Code</span>
                                    <p>
                                        <span>{this.props.school.officeDistanceFromZip} miles</span> away</p>
                                </div> : null
                        }
                    </div>

                </div>
            </li>
        )
    }
}

function mapStateToProps(state: FindAMRAppState): StateToProps {
    return {
        zipCode: state.FindAMRFiltersModel.location,
        zipCodeStatus: state.FindAMRApiModel.locationStatus,
        hasLatLong: (state.FindAMRFiltersModel.latitude !== "" && state.FindAMRFiltersModel.longitude !== "")
    }
}

function mapDispatchToProps(dispatch: Dispatch<FindAMRAppState>, ownProps: ExternalProps): DispatchToProps {
    return {
        setContent: (content: IAMTAMassageResource) => dispatch(setContent(content))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MassageSchoolItemComponent)