import { assign, groupBy } from 'lodash';
import { ActionType } from '../actions/actions';
import { IAction } from '../../classes/action';
import { IPage, defaultPageModel } from "../../classes/page";


export interface FindAMRPaginationModel {
    currentPage: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
}

export const defaultFindAMRPaginationModel: FindAMRPaginationModel = {
    currentPage: 1,
    pageSize: 10,
    totalItems: 0,
    totalPages: 1
}

export default function paginationReducer(state: FindAMRPaginationModel = defaultFindAMRPaginationModel, action: IAction) {
    switch (action.type) {
        case ActionType.SET_PAGE: {
            return assign({}, state, { currentPage: action.value });
        }

        default:
            return state;
    }
}


