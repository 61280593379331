import * as React from 'react';
import { FindAMRPaginationModel } from '../../redux/reducers/pagination-reducer';
import { FindAMRSettingsModel } from '../../redux/reducers/find-a-mr-settings';
import { FindAMRApiModel } from '../../redux/reducers/find-a-mr-api';
import { getRangeCenteredAt } from '../../../global/functions/math';
import * as _ from 'lodash';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import { ActionType, setPage } from '../../redux/actions/actions';
import { connect } from 'react-redux';

/**
 * Specify how the URL gets decoded here. This is an object that takes the prop
 * name as a key, and a query param specifier as the value. The query param
 * specifier can have a `type`, indicating how to decode the value from the
 * URL, and a `queryParam` field that indicates which key in the query
 * parameters should be read (this defaults to the prop name if not provided).
 *
 * The queryParam value for `foo` here matches the one used in the changeFoo
 * action.
 */
const urlPropsQueryConfig = {
    queryCurrentPage: { type: UrlQueryParamTypes.number, queryParam: "page" },
}

function mapStateToProps(state, props) {
    return {
        currentPage: state.FindAMRPaginationModel.currentPage,
        totalPages: state.FindAMRApiModel.totalPages
    };
}

/**
 * Standard react-redux mapDispatchToProps
 */
function mapDispatchToProps(dispatch) {
    return {
        pageSelectProps: (page: number) => dispatch(setPage(page))
    };
}

interface IPageNumber {
    number: number;
    active: boolean;
}

export interface IPageButton extends IPageNumber {
    isLeftMost: boolean;
    isRightMost: boolean;
    isEllipsis: boolean;
    numberOfPages: number;
}

export interface IPagingProps { 
    numberOfButtonsToShow: number;
    paging: FindAMRPaginationModel;
    onPageSelect(page: number): void;
    totalPages: number;
}

export interface IPageButtonProps {
    page: IPageButton;
    key: any;
    onPageSelect(page: number): void;
}

/// <summary>
/// Individual page buttons
/// </summary>
export class PageButton extends React.Component<IPageButtonProps, any> {
    constructor(props) {
        super(props);

        if (props.queryCurrentPage) {
            props.pageSelectProps(props.queryCurrentPage);
        }
    }

    /// <summary>
    /// Determines CSS class names for the button
    /// </summary>
    /// <returns>Css classes</returns>
    GetClasses() {
        return this.props.page.active ? 'selected '
            : this.props.page.isEllipsis ? 'ellipsis '
                : '';
    }

    /// <summary>
    /// Determines text content to display for the button
    /// </summary>
    /// <returns>Content text</returns>
    GetContent() {
        return this.props.page.isEllipsis ? '...'
            : this.props.page.isLeftMost ? 1
                : this.props.page.isRightMost ? this.props.page.numberOfPages
                    : this.props.page.number;
    }



    render() {

        return (
			<li className={this.GetClasses() + 'pagination-buttons-item search-pagination-buttons-item' + ' search-pagination-buttons-item-' + this.GetContent().toString()} onClick={() => this.props.page.isEllipsis || this.props.page.active ? false : this.props.onPageSelect(this.props.page.number)}>
                <p dangerouslySetInnerHTML={{ __html: this.GetContent().toString() }}></p>
            </li>
        );
    }


}

var ConnectedPageButton = addUrlProps({ urlPropsQueryConfig })(connect(mapStateToProps, mapDispatchToProps)(PageButton));

/// <summary>
/// Creates and renders collection of page buttons
/// </summary>
export class Paging extends React.Component<IPagingProps, any> {

    /// <summary>
    /// Creates collection of page buttons to render
    /// </summary>
    /// <returns>Page buttons</returns>
    GetPages() {
        const numberOfPages = this.props.totalPages;
        const pagesMiddle = Math.ceil(this.props.numberOfButtonsToShow / 2);

        //Create list of page number objects
        var pageNumbers = [];
        for (var i = 0; i < (numberOfPages); i++) {
            pageNumbers[i] = { number: i + 1, active: (i + 1) === this.props.paging.currentPage } as IPageNumber;
        }

        //Filter list to only the numbers we will display
        const centered = getRangeCenteredAt(this.props.paging.currentPage, this.props.numberOfButtonsToShow, pageNumbers);

        return centered
            .map((page, i) => {
                //Calculate page-specific attributes
                const isLeftMost = i === 0 && page.number !== 1;
                const isRightMost = page.number !== numberOfPages && page.number !== (numberOfPages - 1) && (i === centered.length - 1);

                const isLeftEllipsis = ((numberOfPages > this.props.numberOfButtonsToShow) && (this.props.paging.currentPage > pagesMiddle)) && i === 1;
                const isRightEllipsis = ((numberOfPages > this.props.numberOfButtonsToShow) && ((numberOfPages - this.props.paging.currentPage) > pagesMiddle)) && i === centered.length - 2;
                const isEllipsis = isLeftEllipsis || isRightEllipsis;

                const number = isLeftMost ? 1
                    : isRightMost ? numberOfPages
                        : page.number;

                return _.assign(page, {
                    isLeftMost,
                    isRightMost,
                    number,
                    numberOfPages,
                    isEllipsis
                }) as IPageButton;
            });
    }

    render() {
        return (
            <ul className="pagination-list" data-id="pageLinks">
                {this.GetPages().map((page, i) => <ConnectedPageButton page={page}
                    key={i}
					onPageSelect={this.props.onPageSelect} />)}
            </ul>
        );
    }
}

