import { combineReducers, createStore, applyMiddleware } from 'redux';
import { Provider, Store } from 'react-redux';
import thunk from 'redux-thunk';
import findAMRApiReducer from './reducers/find-a-mr-api';
import findAMRSettingsReducer from './reducers/find-a-mr-settings';
import { FindAMRApiModel, defaultFindAMRApiModel } from './reducers/find-a-mr-api';
import { FindAMRSettingsModel, defaultFindAMRSettingsModel } from './reducers/find-a-mr-settings';
import { getMassageResources, getSettings } from './actions/find-a-mr-api';
import paginationReducer from "./reducers/pagination-reducer";
import { FindAMRAppState } from '../classes/redux-states';
import filtersReducer from "./reducers/filters-reducer";

let combined = combineReducers({
    FindAMRApiModel: findAMRApiReducer,
    FindAMRSettingsModel: findAMRSettingsReducer,
    FindAMRPaginationModel: paginationReducer,
    FindAMRFiltersModel: filtersReducer
})

var MassageResourceStore = createStore(combined, applyMiddleware(thunk)) as Store<any>;

var previousState = MassageResourceStore.getState();

MassageResourceStore.subscribe(() => {
    const currentState = MassageResourceStore.getState();
    const apiModel = currentState as FindAMRAppState;
    const prevApiModel = previousState as FindAMRAppState;

    if (apiModel.FindAMRPaginationModel.currentPage != prevApiModel.FindAMRPaginationModel.currentPage && !apiModel.FindAMRApiModel.requestingData) {
        MassageResourceStore.dispatch(getMassageResources(0));
        MassageResourceStore.dispatch(getSettings());
    }
    //update here for filtering
    previousState = currentState;
})

export { MassageResourceStore }