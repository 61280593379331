import * as React from 'react';
import { Dispatch, connect } from "react-redux";
import { getMassageResources, requestMassageResources } from "../../redux/actions/find-a-mr-api";
import { setPage, updateTextFilter, setLocationStatus, setZipError } from "../../redux/actions/actions";
import { FindAMRAppState } from "../../classes/redux-states";
import { multiPushInUrlQuery } from 'react-url-query';

export interface DispatchToProps {
    requestMassageResources: (page: number) => any;
    setPage: (page: number) => any;
    updateTextFilter: (filterName: string, filterValue: string) => any;
    setLocationStatus: (locationStatus: boolean) => any;
    setZipError: (zipError: boolean) => any;
}

export interface StateToProps {
    zipError: boolean;
}

type LocationFilterProps = StateToProps & DispatchToProps;

export class FilterItemLocationCodeComponent extends React.Component<LocationFilterProps, any> {
    constructor(props: LocationFilterProps) {
        super(props);

        this.textChangeEvent = this.textChangeEvent.bind(this);
        this.getResults = this.getResults.bind(this);
        this.clearLocation = this.clearLocation.bind(this);
    }

    checkValidZip() {
        var zipCode = (document.getElementById('location') as HTMLInputElement).value;
        var anyNumberRegex = /\d/;
        var zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;

        if (zipCode === '') {
            this.props.setZipError(false);
            return true;
        }
        if (zipCode.match(anyNumberRegex)) {
            if (!zipCode.match(zipCodeRegex)) {
                this.props.setZipError(true);
                return false;
            }
            else {
                this.props.setZipError(false);
                return true;
            }
        } 
        this.props.setZipError(false);
        return true;
    }

    textChangeEvent(event) {
        this.props.updateTextFilter("location", event.target.value);
        this.resultsNullCheck(event.target.value);
    }

    getResults(event) {
        if (event.which == '13') {
            if (this.checkValidZip()) {
                var zipCode = (document.getElementById('location') as HTMLInputElement).value;
                var keywordResult = (document.getElementById('keyword') as HTMLInputElement).value;
                this.props.requestMassageResources(1);
                this.props.setPage(1);
                multiPushInUrlQuery({ location: zipCode, keyword: keywordResult, page:1 });
            }
        }
    }
    clearLocation() {
        this.props.updateTextFilter('location', '');
        //this.props.setLocationStatus(false);
        (document.querySelector('.locationFilter') as HTMLInputElement).value = '';
        (document.querySelector('.locationFilter') as HTMLInputElement).focus();
        (document.querySelector('[data-location-panel]') as HTMLElement).classList.add('inactive');

    }

    resultsNullCheck(value) {
        let searchPanel = document.querySelector('[data-location-panel]');
        if (value != '') {
            searchPanel.classList.remove('inactive');
        }
        else {
            searchPanel.classList.add('inactive');
        }
    }

    getURLSearchParams(name) {
        var searchTermFromQuery = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href); // gets a specific value from the location object's URL and filters out any symbols that may be in the URL
        if (searchTermFromQuery == null) {
            return null;
        }
        else {
            return decodeURI(searchTermFromQuery[1]) || 0;
        }
    }

    getLocationFromQueryString() {

        var locationFromQueryString = this.getURLSearchParams("location");

        if (locationFromQueryString != null && locationFromQueryString != "") {
            this.props.updateTextFilter("location", locationFromQueryString.toString());
            var locationCodeTextBox = document.getElementById("location");
            (locationCodeTextBox as HTMLInputElement).value = locationFromQueryString.toString();
        }
    }

    componentDidMount() { 
        this.getLocationFromQueryString();
        var locationSearch = (document.getElementById('location') as HTMLInputElement).value;
        if (locationSearch != '') {
            this.resultsNullCheck(locationSearch);
        }
    }

    render() {

        return (
            <div className="find-a-mr-filters-panel-item find-a-mr-filters-panel-item-field">
                <label className="visually-hidden" htmlFor="zipCode">Location</label>
                <div className="un-btn find-a-mr-filters-panel-item-svg inactive" data-location-panel>
                    <button className="un-btn icon-close" type="button" onClick={this.clearLocation}>
                        <svg className="svg-icon" aria-hidden="true" focusable="false">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-close"></use>
                        </svg>
                        <span className="visually-hidden">Clear Search</span>
                    </button>
                    <button className="un-btn icon-search" tabIndex={-1}>
                        <svg className="svg-icon" aria-hidden="true" focusable="false">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-location"> </use>
                        </svg>
                        <span className="visually-hidden">Location</span>
                    </button>
                </div>
                <div className="zipcode-container">
                    <input id="location" className="locationFilter" type="text" min="1" step="1" name="location" placeholder="Enter city, state, or zip" onKeyPress={this.getResults} onChange={this.textChangeEvent} />
                    {
                        this.props.zipError ?
                            <div className="errorMsg">
                                <svg className="svg-icon" aria-hidden="true" focusable="false">
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-error"></use>
                                </svg>
                                <p>Please enter a valid ZIP code</p>
                            </div> : null
                    }
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: Dispatch<FindAMRAppState>): DispatchToProps {
    return {
        requestMassageResources: (page: number) => dispatch(getMassageResources(page)),
        setPage: (page: number) => dispatch(setPage(page)),
        updateTextFilter: (filterName: string, filterValue: string) => dispatch(updateTextFilter(filterName, filterValue)),
        setLocationStatus: (locationStatus: boolean) => dispatch(setLocationStatus(locationStatus)),
        setZipError: (zipError: boolean) => dispatch(setZipError(zipError))
    }
}

function mapStateToProps(state: FindAMRAppState): StateToProps {
    return {
        zipError: state.FindAMRApiModel.zipError
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterItemLocationCodeComponent);